import Link from 'next/link'; 

const HeroLandingMobile = () => {
  return (
    <>
      <div className="hero-landing">
        <img src="/assets/cuadros.jpg" alt="portada-pelota-lpf" />
        <div className="hero-claim">
          <h2 className='logo'>
            <img src="/assets/wooloo-logo-sq.png" alt="logo LPF" />
          </h2>
          <h3>
          TODO el deporte, donde y cuando quieras
          </h3>
          <div className="hero-login">
            <p className="secondary-button">
              <Link href="/signin">
                Ingresá con tu cuenta 
              </Link>
            </p>
            <p> ó </p>
            <p className="primary-button">
              <Link href="/signup">Registrate</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  )
} 

export { HeroLandingMobile }; 

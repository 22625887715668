import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import Head from 'next/head';
import { useEffect } from 'react';

export default function Layout({ title, className, children }) {
    useEffect(() => {
        // Unlock scroll
        document.querySelector('body').style.overflow = 'visible';
    }, []);

    return (
        <>
            <Head>
                <title>{title}</title>
                <link
                    href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700&family=Ubuntu:wght@300;400;500;700&display=swap"
                    rel="stylesheet"
                    key="google-font-Ubuntu"
                />
            </Head>
            <div className={className}>
                <Header />
                <main>{children}</main>
                <div id="modal"></div>
                <Footer />
            </div>
        </>
    );
}

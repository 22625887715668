import Link from 'next/link';

const NavbarLogged = () => {
    return (
        <div className="navbar">
            <Link href="/my-account">
                <a className="primary-button">Mi Cuenta</a>
            </Link>
        </div>
    );
};

export { NavbarLogged };
